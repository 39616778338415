/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Stepper, Step, StepLabel, Box, Button,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/es';
import moment from 'moment';
import CustomInput from '../../../components/form/CustomInput';
import CustomSelect from '../../../components/form/CustomSelect';
import AutocompleteGoogleMaps from '../../../components/form/AutocompeteGoogleMaps/CustomAutocompleteGoogleMaps';
import { Rules } from '../../../helpers/RHFRules';
import {
  getPurchaseOrderParametersAction,
} from '../../../actions/general.action';
import {
  saveContractAction, getManagersAction, getAppParametersContractsAction, getContractDataAction,
} from '../../../actions/license.action';
import { getPackagesContractsAction } from '../../../actions/home.action';
import { getDocumentTypesContracts } from '../../../helpers/documentsTypes';
import AutoCompleteAddressDepartmentCity from '../../../components/form/AutocompeteGoogleMaps/AutoCompleteAddressDepartmentCity';
import AlertConfirm from '../../../components/crosscutting/AlertConfirm';
import Loading from '../../../components/crosscutting/Loading';
import { getBlackListEmailsAction } from '../../../actions/email.action';
import { getServicesAction } from '../../../actions/service.action';
import { LICENSE_PRIVILEGE_FULL_ACCESS } from '../../../config/config';
import CertificationServices from './CertificationServices';
import FirmayaServices from './FirmayaServices';
import QuickSummary from './QuickSummary';
import WarningIcon from '../../../assets/img/iconos/license/WarningIcon.svg';
import SuccesfullIcon from '../../../assets/img/iconos/license/SuccesfullIcon.svg';

const getMulishText = (fontWeight, color, fontSize) => ({
  fontFamily: 'Mulish, sans-serif',
  fontSize,
  fontWeight,
  color,
});

/**
 * Set styles for Dialog
 * @param {*} alertIcon dinamic
 * @param {*} minWidth dinamic
 * @param {*} minHeight dinamic
 * @returns complete Styles for dialog
 */
const alertStyles = (alertIcon, minWidth, minHeight, marginTopImage) => ({
  styleMessage: {
    ...getMulishText(700, '#575756', '18px'),
    paddingBottom: 15,
  },
  dialogStyle: { minWidth, minHeight },
  firstImage: alertIcon,
  firstImageStyles: {
    display: 'block',
    margin: 'auto',
    width: '96px',
    height: 'auto',
    marginTop: marginTopImage,
  },
  styleTitle: {
    fontFamily: 'Mulish, sans-serif',
    fontSize: '20px',
    fontWeight: 700,
    marginTop: '-25px',
  },
  styleButtonCancel: {
    backgroundColor: '#FFFFFF',
    color: '#1D71B8',
    border: '1px solid #1D71B8',
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    background: 'linear-gradient(#FFFFFFCC, #ffffff)',
    borderRadius: '30px',
    opacity: 1,
    padding: '5px',
    marginTop: '20px',
  },
  title: {
    fontWeight: 600,
    fontSize: '26px',
    paddingTop: '15px',
    paddingLeft: '20px',
    color: theme.backOffice.contracts.title.color,
  },
  titleForm: {
    color: theme.backOffice.contracts.titleForm.color,
    textAlign: 'left',
    letterSpacing: '0px',
    opacity: '1',
    fontWeight: 'bold',
  },
  typeInput: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '10px',
      boxShadow: '1px 5px 5px #d7d7d7',
    },
  },
  paperGroup: {
    backgroundColor: '#fff',
    margin: 20,
    borderRadius: 10,
    border: '1px solid #32ACDE',
    padding: 20,
  },
  dateCustom: {
    '& .MuiIconButton-root': {
      color: theme.palette.primary.main,
    },
  },
  subGridButtons: {
    display: 'flex',
    flexDirection: 'column',
  },
  btnSubmit: {
    marginLeft: 10,
    fontSize: 16,
    width: '120px',
    height: '44px',
    borderRadius: 10,
    fontWeight: 100,
    border: `1px solid ${theme.backOffice.contracts.btnSteps.backgroundColor}`,
    color: theme.backOffice.contracts.btnSteps.backgroundColor,
    backgroundColor: 'white',
    '&:disabled': {
      opacity: 0.4,
      fontWeight: 100,
      backgroundColor: 'white',
      color: theme.backOffice.contracts.btnSteps.backgroundColor,
    },
    '&:hover': {
      backgroundColor: '#E0E0E0',
      boxShadow: '#00000033 0px 2.98931px 1.03206px -1.98931px, #00000024 0px 2.02137px 2.03206px 0px, #0000001F 0px 1px 5.05343px 0px',
    },
  },
  btnBlueSubmit: {
    marginLeft: 10,
    fontSize: 16,
    width: 200,
    borderRadius: 10,
    backgroundColor: theme.backOffice.contracts.btnSteps.backgroundColor,
    color: 'white',
    fontWeight: 100,
    '&:disabled': {
      opacity: 0.4,
      fontWeight: 100,
      backgroundColor: theme.backOffice.contracts.btnSteps.backgroundColor,
      color: 'white',
    },
  },
  scrollBar: {
    '&.MuiTableContainer-root': {
      scrollbarColor: `${theme.backOffice.contracts.tableProducts.backgroundColor} #fff`,
    },
  },
  tableHead: {
    width: '828px',
    height: '40px',
    background: 'var(--unnamed-color-009bdd) 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    opacity: 1,
    color: '#FFF',
    backgroundColor: theme.backOffice.contracts.tableProducts.backgroundColor,
  },
  tableCell: {
    fontWeight: 'bold',
    fontSize: '16px',
    ...theme.backOffice.contracts.tableProducts,
  },
  AlertStyleTitle: {
    fontFamily: 'Mulish, sans-serif',
    fontSize: 700,
    fontWeight: '20px',
    marginTop: '-25px',
  },
  AlertFirstImageStyles: {
    display: 'block',
    margin: 'auto',
    width: '96px',
    height: 'auto',
    marginTop: 40,
  },
}));

const personType = [
  { value: 'J', label: 'Persona Juridica' },
  { value: 'N', label: 'Persona Natural' },
];

const steps = ['Información Contrato', 'Información Cliente', 'Información Supervisor', 'Servicios Contratados', 'Resumen'];

const Agreements = (props) => {
  const {
    statesAndCities, citiesServGoogleAssistant, getPurchaseOrderParameters, getServices, showMessageAlert,
    documentTypes, tenant,
    saveContract, getManagers, gestorType,
    getPackagesContracts, productType,
    loading, getBlackListEmails, emailsInBalckList, getAppParametersContracts, listPaymentTypeContracts, listDocumentTypeContracts,
    listValidityTypeContracts, listDeviceTypeContracts, listCredentialDeliveryContracts,
    parameterMaxReassignmentQuota, startDateParams, transformedListMaxValidityAmountContracts, getContractData, contractData, decodedToken,
    servicesCombo,
  } = props;
  const {
    handleSubmit, errors, control, getValues, setValue, clearErrors, watch, setError,
  } = useForm({
    mode: 'onChange',
  });
  console.log('props', props);
  console.log('listPaymentTypeContracts', listPaymentTypeContracts);
  const {
    handleSubmit: handleSubmitProducts,
    errors: errorsProducts,
    control: controlProducts,
    setValue: setValueProducts,
    reset: resetProduct,
    clearErrors: clearErrorsProducts,
    getValues: getValuesProducts,
  } = useForm({
    mode: 'onChange',
  });
  const theme = useTheme();

  const watchDocTypeSer = watch('documentTypeIdService');
  const watchDocTypeDocSupervisor = watch('documentTypeIdSupervisor');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [personTypeService, setPersonTypeService] = React.useState('N');
  const [optionsAddressService, setOptionsAddressService] = React.useState([]);
  const [addressMapsService, setAddressMapsService] = React.useState(null);
  const [inputAddressServiceValue, setInputAddressServiceValue] = React.useState('');
  const [, setGeoCodeService] = React.useState({ lat: null, lng: null });
  const [departaments, setDepartaments] = useState([]);
  const [citiesService, setCitiesService] = useState([]);
  const [valueDepartamentService, setValueDepartamentService] = React.useState(null);
  const [valueCityService, setValueCityService] = React.useState(null);
  const [servicesToAdd, setServicesToAdd] = React.useState([]);
  const classes = useStyles();
  const [addressMapsSon, setAddressMapsSon] = useState();
  const [inputAddressSonValue, setInputAddressSonValue] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [credentialsOptions, setCredentialsOptions] = useState(listCredentialDeliveryContracts);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [dataComplete, setDataComplete] = useState(null);
  const [lastValidValueQuantityProducts, setLastValidValueQuantityProducts] = useState('');
  const [invalidDomains, setInvalidDomains] = useState([]);
  const [invalidContract, setInvalidContract] = useState(false);
  const [isChangeService, setIsChangeService] = useState(false);
  const [serviceNameSelected, setServiceNameSelected] = useState(null);
  const [componentNameSelected, setComponentNameSelected] = useState(null);
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(false);
  const [isRequiredSupervision, setIsRequiredSupervision] = useState(false);

  const [optionsAddressSupervisor, setOptionsAddressSupervisor] = useState([]);
  const [departamentsSupervisor, setDepartamentsSupervisor] = useState([]);
  const [, setGeoCodeSupervisor] = useState({
    lat: null,
    lng: null,
  });
  const [valueDepartamentSupervisor, setValueDepartamentSupervisor] = useState(null);
  const [valueCitySupervisor, setValueCitySupervisor] = useState(null);
  const [citiesSupervisor, setCitiesSupervisor] = useState([]);
  const [serviceOptions, setServiceOptions] = React.useState([]);
  const [messagesAlertView, setMessagesAlertView] = useState({});
  const setCityCustomerServiceId = (dptoId, dptoName, cityId, cityName, checkServ) => {
    if (checkServ) {
      let dptoServ = {};
      if (dptoId > 0) {
        dptoServ = statesAndCities.itemsStatesList.find((d) => d.value === dptoId);
      } else {
        dptoServ = statesAndCities.itemsStatesList.find((d) => d.text === dptoName);
        // dptoBilling = statesAndCities.itemsStatesList.find((d) => dptoName.include(d.text));
      }

      if (dptoServ) {
        setValueDepartamentService(dptoServ);
        setValue('departmentIdService', dptoServ);
        clearErrors('departmentIdService');
      }

      let newCities = {};
      if (dptoId > 0) {
        newCities = statesAndCities.statesList.find((d) => d.id === dptoId);
      } else {
        newCities = statesAndCities.statesList.find((d) => d.stateName === dptoName);
      }
      if (newCities) {
        setCitiesService(newCities.city);

        let cityBilling = {};
        if (cityId > 0) {
          cityBilling = newCities.city.find((d) => d.id === cityId);
        } else {
          cityBilling = newCities.city.find((d) => d.cityName === cityName);
        }

        if (cityBilling) {
          setValueCityService(cityBilling);
          setValue('departmentIdService', dptoServ.value);
          clearErrors('departmentIdService');
          setValue('cityIdService', cityBilling.id);
          clearErrors('cityIdService');
        }
      }
    }
  };

  const confirmSaveContract = () => {
    if (dataComplete) {
      saveContract(dataComplete, serviceNameSelected, isRequiredSupervision);
    }
    setOpenAlertConfirm(false);
  };

  const showAlertConfirm = () => {
    setMessagesAlertView({
      tituloModal: 'Guardar convenio',
      textButtonAcept: 'Guardar convenio',
      mensajeModal: 'Ten en cuenta que una vez este convenio se guarde, no podrá ser modificado.',
      // handleAccept: () => confirmSaveContract(), concurrency problem
      onCancel: true,
      onClose: () => setOpenAlertConfirm(false),
      messageAlert: alertStyles(WarningIcon, '508px', '369px', 40),
      showClose: false,
      isOnlyMessage: false,
    });
    setOpenAlertConfirm(true);
  };

  useEffect(() => {
    const getValueServiceName = getValues('serviceName');
    if (getValueServiceName && isChangeService) {
      setValue('gestorService', '');
      setValue('contractService', '');
      const filteredServices = serviceOptions.find((serviceItemCombo) => serviceItemCombo.value === getValueServiceName);
      setServiceNameSelected(filteredServices?.nameContract);
      setComponentNameSelected(filteredServices?.contractedServices);
      getManagers(`LICENSE_${filteredServices?.nameContract}_COMERCIALMANAGER`);
      getPackagesContracts(filteredServices?.name, true, true);
      getAppParametersContracts(filteredServices?.nameContract, 'ParametersDocumentType', 'ParametersValidityType', 'ParametersStartDate', 'ParameterMaxReassignmentQuota', 'ParameterMaxValidityAmount');
      setIsRequiredSupervision(filteredServices?.requiresSupervision);
    }
  }, [getValues('serviceName'), isChangeService]);

  useEffect(() => {
    getPurchaseOrderParameters();
    getServices();
    getBlackListEmails();
  }, []);

  useEffect(() => {
    if (statesAndCities) {
      setDepartaments(statesAndCities.itemsStatesList.sort((a, b) => {
        const x = a.text < b.text ? -1 : 1;
        return x;
      }));
    }
  }, [statesAndCities]);

  /**
   * emails in black list retrived of redux
   * then parse into a unique list
   */
  useEffect(() => {
    if (emailsInBalckList !== null && emailsInBalckList !== undefined) {
      const invalidsDomains = [];
      emailsInBalckList.forEach((email) => {
        invalidsDomains.push(email.regla);
      });
      setInvalidDomains(invalidsDomains);
    }
  }, [emailsInBalckList]);

  useEffect(() => {
    if (servicesCombo.length > 0) {
      let selectedApps = [];
      Object.keys(decodedToken).forEach((key) => {
        if (key.includes('PRIVILEGE')) {
          const privileges = decodedToken[key];
          if (Array.isArray(privileges)) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < privileges.length; i++) {
              const privilege = privileges[i];
              if (privilege === LICENSE_PRIVILEGE_FULL_ACCESS) {
                selectedApps = [];
                const apps = servicesCombo.filter((serviceItemCombo) => serviceItemCombo.isContractFullAccess === true);
                if (apps.length > 0) {
                  // eslint-disable-next-line no-loop-func
                  apps.forEach((app) => {
                    if (app.name) {
                      selectedApps.push(app.name);
                    }
                  });
                }
                break;
              }
              const matchApp = privilege.match(/LICENSE_([A-Z]+)_/);
              if (matchApp && matchApp[1]) {
                selectedApps.push(matchApp[1]);
              }
            }
          }
        }
      });
      const filteredServices = servicesCombo.filter((serviceItemCombo) => selectedApps.includes(serviceItemCombo.name));
      const serviceOption = filteredServices.map((serviceItemCombo) => ({
        value: serviceItemCombo.value,
        label: serviceItemCombo.label,
        name: serviceItemCombo.name,
        nameContract: serviceItemCombo.nameContract,
        contractedServices: serviceItemCombo.contractedServices,
        requiresSupervision: serviceItemCombo.requiresSupervision,
      }));
      setServiceOptions(serviceOption);
    }
  }, [servicesCombo, decodedToken]);
  /**
   * Validate if domain is not in black list
   */
  const validateEmail = (value) => {
    if (!value) {
      return true;
    }
    // Verify if email have a domain in black list
    const domain = value.split('@')[1];
    if (domain && invalidDomains.includes(`@${domain}`)) {
      return 'El dominio de correo ingresado no es permitido.';
    }
    return true;
  };

  useEffect(() => {
    if (citiesServGoogleAssistant && citiesServGoogleAssistant.deptoName !== null) {
      setCityCustomerServiceId(
        0, citiesServGoogleAssistant.deptoName, 0, citiesServGoogleAssistant.cityName, true,
      );
    } else if (addressMapsService && optionsAddressService.length > 3) {
      if (!valueDepartamentService) {
        setValueDepartamentService(null);
        setValue('departmentIdService', '');
      }
      if (!valueCityService) {
        setValueCityService(null);
        setValue('cityIdService', '');
      }
    }
  }, [citiesServGoogleAssistant]);

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    }
  }, [formData, setValue, activeStep]);

  useEffect(() => {
    if (!valueDepartamentService) {
      setValue('departmentIdService', '');
    }
  }, [valueDepartamentService]);

  useEffect(() => {
    if (!valueCityService) {
      setValue('cityIdService', '');
    }
  }, [valueCityService]);

  const handleNext = (data) => {
    const currentData = getValues();

    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));

    if (activeStep === steps.length - 1) {
      let transformedServices;
      if (componentNameSelected && componentNameSelected === 'isComponentServices') {
        transformedServices = servicesToAdd.map((item) => ({
          packageCode: item.productService,
          quantityToAdd: item.quantityToAddService ? parseInt(item.quantityToAddService, 10) : 0,
          identifierCode: `${item.productService}_${item.validityAmount}${item.validityService}`,
          typeValidity: item.validityService,
          validityAmount: parseInt(item.validityAmount, 10),
          IsUnlimited: item.isSwitchEnabled,
        }));
      } else {
        transformedServices = servicesToAdd.map((item) => ({
          packageCode: item.productService,
          quantityToAdd: parseInt(item.quantityToAddService, 10),
          identifierCode: `${item.productService}_${listDeviceTypeContracts.find((validity) => validity.value === item.deviceType)?.codeDevice}_${item.validityAmount}${item.validityService}`,
          typeValidity: item.validityService,
          validityAmount: parseInt(item.validityAmount, 10),
          reassignmentQuota: parseInt(item.reassignmentQuota, 10),
          deviceType: item.deviceType,
          credentialsDeliveryType: item.credentialsDeliveryType,
        }));
      }
      const finalData = { ...formData, ...currentData };
      finalData.servicesToAdd = transformedServices;
      setDataComplete(finalData);
      showAlertConfirm();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setIsChangeService(false);
  };

  const handleBack = () => {
    const currentData = getValues();
    setFormData((prevData) => ({
      ...prevData,
      ...currentData,
    }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setPersonType = (event) => {
    setValue('documentTypeIdService', '');
    setValue('legalNameService', '');
    setValue('firstNameService', '');
    setValue('lastNameService', '');
    setValue('documentNumberService', '');
    const { value } = event.target;

    if (event.target.name === 'personTypeService') {
      setPersonTypeService(value);
    }
    return value;
  };

  const getDocumentsTypesSupervisor = () => {
    const documents = [];
    getDocumentTypesContracts(documentTypes, 'N', serviceNameSelected, listDocumentTypeContracts, true).map(
      (td) => (
        documents.push({
          value: td.value,
          label: td.text,
        })
      ),
    );
    return documents;
  };

  const setGestorType = (event) => {
    const { value } = event.target;
    if (event.target.name === 'gestorService') {
      setValue('gestorService', value);
    }
    return value;
  };

  const setPaymentType = (event) => {
    const { value } = event.target;
    if (event.target.name === 'paymentService') {
      setValue('paymentService', value);
    }
    return value;
  };
  const setServiceType = (event) => {
    const { value } = event.target;
    if (event.target.name === 'serviceName') {
      setValue('serviceName', value);
    }
    setIsChangeService(true);
    setServicesToAdd([]);
    return value;
  };

  const validateDocument = (persontype, watchDocType) => {
    if (watchDocType === 6 || watchDocType === 10 || watchDocType === 13) {
      return Rules.pasaporte;
    } if (watchDocType === 4 && persontype === 'J') {
      return Rules.tipoDocumentoNIT;
    }
    return Rules.otrosDocumentos;
  };

  const handleChangeStateCustomerServiceId = (idDepartament) => {
    setValueCityService(null);
    const newCities = statesAndCities.statesList.find((d) => d.id === idDepartament);
    if (newCities) {
      setCitiesService(newCities.city.sort((a, b) => {
        const x = a.cityName < b.cityName ? -1 : 1;
        return x;
      }));
    }
    return idDepartament;
  };

  const handleSearchContracNumber = (contractNumber) => {
    getContractData(contractNumber, serviceNameSelected);
  };

  const handleSwitchChange = (event) => {
    setIsSwitchEnabled(event.target.checked);
  };

  useEffect(() => {
    if (contractData && contractData.existContract) {
      setError('contractService', {
        type: 'required',
        message: 'Número de contrato ya se encuentra registrado.',
      });
      setInvalidContract(true);
    } else {
      setInvalidContract(false);
    }
  }, [contractData]);

  useEffect(() => {
    if (showMessageAlert?.message) {
      if (showMessageAlert.isSaved) {
        setMessagesAlertView({
          tituloModal: showMessageAlert?.message,
          messageAlert: alertStyles(SuccesfullIcon, '508px', '284px', 65),
          isOnlyMessage: true,
          showClose: false,
        });
      } else {
        setMessagesAlertView({
          tituloModal: showMessageAlert?.message,
          messageAlert: alertStyles(WarningIcon, '508px', '284px', 65),
          isOnlyMessage: true,
          showClose: false,
        });
      }
      setOpenAlertConfirm(true);
    }
  }, [showMessageAlert]);

  // eslint-disable-next-line consistent-return
  const selectComponent = () => {
    switch (componentNameSelected) {
      case 'isComponentECD':
        return (
          <CertificationServices
            productType={productType}
            listDeviceTypeContracts={listDeviceTypeContracts}
            listValidityTypeContracts={listValidityTypeContracts}
            transformedListMaxValidityAmountContracts={transformedListMaxValidityAmountContracts}
            parameterMaxReassignmentQuota={parameterMaxReassignmentQuota}
            listCredentialDeliveryContracts={listCredentialDeliveryContracts}
            setValueProducts={setValueProducts}
            getValuesProducts={getValuesProducts}
            resetProduct={resetProduct}
            clearErrorsProducts={clearErrorsProducts}
            controlProducts={controlProducts}
            errorsProducts={errorsProducts}
            handleSubmitProducts={handleSubmitProducts}
            theme={theme}
            classes={classes}
            deviceTypeOptions={deviceTypeOptions}
            setDeviceTypeOptions={setDeviceTypeOptions}
            lastValidValueQuantityProducts={lastValidValueQuantityProducts}
            setLastValidValueQuantityProducts={setLastValidValueQuantityProducts}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            servicesToAdd={servicesToAdd}
            setServicesToAdd={setServicesToAdd}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            credentialsOptions={credentialsOptions}
            setCredentialsOptions={setCredentialsOptions}
          />
        );
      case 'isComponentServices':
        return (
          <FirmayaServices
            productType={productType}
            listValidityTypeContracts={listValidityTypeContracts}
            transformedListMaxValidityAmountContracts={transformedListMaxValidityAmountContracts}
            setValueProducts={setValueProducts}
            getValuesProducts={getValuesProducts}
            resetProduct={resetProduct}
            clearErrorsProducts={clearErrorsProducts}
            controlProducts={controlProducts}
            errorsProducts={errorsProducts}
            handleSubmitProducts={handleSubmitProducts}
            theme={theme}
            classes={classes}
            lastValidValueQuantityProducts={lastValidValueQuantityProducts}
            setLastValidValueQuantityProducts={setLastValidValueQuantityProducts}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            servicesToAdd={servicesToAdd}
            setServicesToAdd={setServicesToAdd}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            isSwitchEnabled={isSwitchEnabled}
            handleSwitchChange={handleSwitchChange}
            setIsSwitchEnabled={setIsSwitchEnabled}
          />
        );
      default:
        break;
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ paddingLeft: '0px' }}>
                <Typography align="left" className={classes.titleForm} variant="h6">
                  Información contrato
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                  <InputLabel />
                  <CustomSelect
                    label="Producto"
                    name="serviceName"
                    options={serviceOptions}
                    control={control}
                    required
                    rule={Rules.required}
                    error={errors}
                    styleFromHelperText={{ style: { marginRight: 10 } }}
                    inputProps={{ onChange: (e) => setServiceType(e) }}
                    classes={classes.typeInput}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label="Número de oportunidad"
                  name="contractService"
                  control={control}
                  error={errors}
                  required
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  // rule={(e) => validateContract(e.target.value)}
                  rule={{
                    ...Rules.required,
                  }}
                  inputProps={{
                    maxLength: 100,
                    onBlur: (e) => handleSearchContracNumber(e.target.value),
                    onChange: () => setInvalidContract(true),
                  }}
                  classes={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl style={{ width: '100%' }}>
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{
                      required: 'Campo obligatorio',
                      validate: {
                        validDate: (value) => {
                          if (!moment(value, 'DD/MM/YYYY', true).isValid()) {
                            return 'Fecha inválida, el formato debe ser dd/MM/yyyy';
                          }
                          const minDate = moment('01/01/2000', 'DD/MM/YYYY');
                          const maxDate = startDateParams.isBeyondOneMonth ? moment().add(startDateParams.quantity, startDateParams.dateUnit) : moment();
                          const selectedDate = moment(value, 'DD/MM/YYYY');
                          if (selectedDate.isBefore(minDate)) {
                            return 'Fecha mínima permitida 01/01/2000';
                          }
                          if (selectedDate.isAfter(maxDate)) {
                            return `Fecha máxima permitida ${maxDate.format('DD/MM/YYYY')}`;
                          }
                          return true;
                        },
                      },
                    }}
                    defaultValue={startDate}
                    render={() => (
                      <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.dateCustom}
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          label="Fecha inicio *"
                          size="small"
                          color="primary"
                          value={startDate}
                          maxDate={startDateParams.isBeyondOneMonth ? moment().add(startDateParams.quantity, startDateParams.dateUnit) : moment()}
                          onChange={(date) => {
                            setValue('endDate', '');
                            setEndDate(null);
                            setValue('startDate', moment(date));
                            setStartDate(moment(date));
                            if (moment(date, 'DD/MM/YYYY', true).isValid()
                              && !moment(date).isBefore(moment('01/01/2000', 'DD/MM/YYYY'))
                              && !moment(date).isAfter(startDateParams.isBeyondOneMonth ? moment().add(startDateParams.quantity, startDateParams.dateUnit) : moment())) {
                              clearErrors('startDate');
                            }
                          }}
                          autoOk
                          error={!!errors.startDate}
                          helperText={errors.startDate ? errors.startDate.message : ''}
                          InputProps={{
                            style: {
                              borderRadius: '10px',
                              boxShadow: '1px 5px 5px #d7d7d7',
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl style={{ width: '100%' }}>
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{
                      required: 'Campo obligatorio',
                      validate: {
                        validDate: (value) => {
                          const minDate = startDate ? moment(startDate) : moment();
                          const maxDate = startDate ? moment(startDate).add(2, 'years') : moment();
                          if (!moment(value, 'DD/MM/YYYY', true).isValid()) {
                            return 'Fecha inválida, el formato debe ser dd/MM/yyyy';
                          }
                          if (moment(value).isBefore(minDate)) {
                            return `La fecha debe ser posterior a ${minDate.format('DD/MM/YYYY')}`;
                          }
                          if (moment(value).isAfter(maxDate)) {
                            return `La fecha no puede ser mayor a ${maxDate.format('DD/MM/YYYY')}`;
                          }
                          return true;
                        },
                      },
                    }}
                    defaultValue={endDate}
                    render={() => (
                      <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.dateCustom}
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          label="Fecha fin *"
                          size="small"
                          color="primary"
                          value={endDate}
                          minDate={startDate ? moment(startDate).add(1, 'days') : moment()}
                          maxDate={startDate ? moment(startDate).add(2, 'years') : moment()}
                          onChange={(date) => {
                            setValue('endDate', moment(date));
                            setEndDate(moment(date));
                            clearErrors('endDate');
                          }}
                          autoOk
                          error={!!errors.endDate}
                          helperText={errors.endDate ? errors.endDate.message : ''}
                          InputProps={{
                            style: {
                              borderRadius: '10px',
                              boxShadow: '1px 5px 5px #d7d7d7',
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: 20, paddingBottom: 0 }}>
              <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                  <InputLabel />
                  <CustomSelect
                    label="Gestor operativo"
                    name="gestorService"
                    options={gestorType}
                    control={control}
                    required
                    rule={Rules.required}
                    error={errors}
                    styleFromHelperText={{ style: { marginRight: 10 } }}
                    inputProps={{ onChange: (e) => setGestorType(e) }}
                    classes={classes.typeInput}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                  <InputLabel />
                  <CustomSelect
                    label="Condición de pago"
                    name="paymentService"
                    options={listPaymentTypeContracts}
                    control={control}
                    required
                    rule={Rules.required}
                    error={errors}
                    styleFromHelperText={{ style: { marginRight: 10 } }}
                    inputProps={{ onChange: (e) => setPaymentType(e) }}
                    classes={classes.typeInput}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} />
            </Grid>
          </>
        );
      case 1:
        return (
          <>
            <Grid item xs={12} style={{ paddingLeft: '0px' }}>
              <Typography align="left" className={classes.titleForm} variant="h6">
                Información cliente
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel />
              <CustomSelect
                label="Tipo de persona"
                name="personTypeService"
                options={personType}
                control={control}
                required
                rule={Rules.required}
                error={errors}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{ onChange: (e) => setPersonType(e) }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Controller
                    as={(
                      <TextField
                        select
                        label="Tipo Identificación"
                        size="small"
                        variant="outlined"
                        required
                        fullWidth
                        style={{ marginRight: 10, color: 'red' }}
                        FormHelperTextProps={{ style: { marginRight: 10 } }}
                        helperText={errors.documentTypeIdService ? errors.documentTypeIdService.message : ''}
                        className={classes.typeInput}
                      >
                        <MenuItem value="">Seleccione</MenuItem>
                        {
                          getDocumentTypesContracts(documentTypes, personTypeService, serviceNameSelected, listDocumentTypeContracts).map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )
                        }
                      </TextField>
                    )}
                    name="documentTypeIdService"
                    control={control}
                    rules={Rules.required}
                    error={Boolean(errors.documentTypeIdService)}
                    defaultValue=""
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <CustomInput
                    label="Identificación"
                    name="documentNumberService"
                    control={control}
                    rule={validateDocument(personTypeService, watchDocTypeSer)}
                    error={errors}
                    required
                    styleFromHelperText={{ style: { marginRight: 10 } }}
                    classes={classes.typeInput}
                  />
                </Grid>
              </Grid>
            </Grid>
            {
              personTypeService === 'J'
                ? (
                  <Grid item xs={12}>
                    <CustomInput
                      label="Razón Social"
                      name="legalNameService"
                      control={control}
                      rule={Rules.DenySpecialCharactersLegalName}
                      error={errors}
                      required
                      styleFromHelperText={{ style: { marginRight: 10 } }}
                      inputProps={{
                        maxLength: 450,
                      }}
                      classes={classes.typeInput}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        label="Nombres"
                        name="firstNameService"
                        control={control}
                        rule={Rules.letrasTildesMin3}
                        error={errors}
                        required
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <CustomInput
                        label="Apellidos"
                        name="lastNameService"
                        control={control}
                        rule={Rules.letrasTildesMin3}
                        error={errors}
                        required
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                  </>
                )
            }
            <Grid item xs={12} md={6}>
              {tenant ? (
              // If tenant show Input Without assistant
                <CustomInput
                  label="Dirección"
                  name="fulladdressService"
                  control={control}
                  error={errors}
                  required
                  rule={Rules.max200Characters}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  classes={classes.typeInput}
                />
              ) : (
              // If tenant is null, show other component.
                <AutocompleteGoogleMaps
                  name="fulladdressService"
                  options={optionsAddressService}
                  disabled={!(statesAndCities && Object.keys(statesAndCities).length > 0)}
                  label="Dirección"
                  placeholder="Escriba la dirección"
                  value={addressMapsService}
                  setOptions={setOptionsAddressService}
                  setValueMaps={setAddressMapsService}
                  inputValue={inputAddressServiceValue}
                  setInputValue={setInputAddressServiceValue}
                  setGeoCoordinate={setGeoCodeService}
                  type="Service"
                  showIconLocation
                  control={control}
                  rules={Rules.required}
                  error={errors}
                  className={classes.typeInput}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Controller
                render={(props) => (
                  <Autocomplete
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    options={departaments}
                    noOptionsText="No hay registros"
                    size="small"
                    disableClearable
                    required
                    fullWidth
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Departamento"
                        variant="outlined"
                        required
                        error={Boolean(errors.departmentIdService)}
                        style={{ marginRight: 10, color: 'red' }}
                        FormHelperTextProps={{ style: { marginRight: 10 } }}
                        helperText={errors.departmentIdService ? errors.departmentIdService.message : ''}
                        className={classes.typeInput}
                      />
                    )}
                    value={valueDepartamentService}
                    onChange={(_, data) => {
                      // eslint-disable-next-line react/destructuring-assignment
                      props.onChange(data);
                      if (data) {
                        setValueDepartamentService(data);
                        handleChangeStateCustomerServiceId(data.value);
                      }
                    }}
                  />
                )}
                defaultValue=""
                name="departmentIdService"
                control={control}
                rules={Rules.required}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Controller
                render={(props) => (
                  <Autocomplete
                    {...props}
                    options={citiesService}
                    noOptionsText="No hay registros"
                    disableClearable
                    size="small"
                    required
                    fullWidth
                    getOptionLabel={(option) => option.cityName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Municipio"
                        variant="outlined"
                        required
                        error={Boolean(errors.cityIdService)}
                        style={{ marginRight: 10, color: 'red' }}
                        FormHelperTextProps={{ style: { marginRight: 10 } }}
                        helperText={errors.cityIdService ? errors.cityIdService.message : ''}
                        className={classes.typeInput}
                      />
                    )}
                    value={valueCityService}
                    onChange={(_, data) => {
                      props.onChange(data);
                      if (data) {
                        setValueCityService(data);
                      }
                    }}
                  />
                )}
                defaultValue=""
                name="cityIdService"
                control={control}
                rules={Rules.required}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Teléfono / Celular"
                name="cellphoneNumberService"
                control={control}
                rule={Rules.telCel}
                error={errors}
                required
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{
                  maxLength: 10,
                }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Correo Electrónico"
                name="emailService"
                control={control}
                rule={{
                  ...Rules.email,
                  validate: validateEmail, // personalizated validation
                }}
                error={errors}
                required
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{
                  maxLength: 100,
                }}
                classes={classes.typeInput}
              />
            </Grid>
          </>
        );
      case 2:
        return (
          <Grid container spacing={3} alignItems="center" style={{ marginBottom: '10px' }}>
            <Grid item xs={12} style={{ paddingLeft: '0px' }}>
              <Typography align="left" className={classes.titleForm} variant="h6">
                Información supervisor
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel />
              <CustomSelect
                label="Tipo Identificación"
                name="documentTypeIdSupervisor"
                // eslint-disable-next-line no-nested-ternary
                options={getDocumentsTypesSupervisor()}
                control={control}
                required
                rule={Rules.required}
                error={errors}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                // inputProps={{ onChange: (e) => setPersonType(e) }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Identificación:"
                name="documentNumberSupervisor"
                control={control}
                error={errors}
                required
                rule={validateDocument('N', watchDocTypeDocSupervisor)}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Nombres: "
                name="namesSupervisor"
                control={control}
                error={errors}
                required
                rule={Rules.letrasTildesMin3}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Apellidos: "
                name="lastNameSupervisor"
                control={control}
                error={errors}
                required
                rule={Rules.letrasTildesMin3}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                classes={classes.typeInput}
              />
            </Grid>
            <AutoCompleteAddressDepartmentCity
              control={control}
              errors={errors}
              styles={classes.typeInput}
              setValue={setValue}
              getValues={getValues}
              addressMapsSon={addressMapsSon}
              setAddressMapsSon={setAddressMapsSon}
              inputAddressSonValue={inputAddressSonValue}
              setInputAddressSonValue={setInputAddressSonValue}
              tenant={tenant}
              stylesForInput={classes.typeInput}
              optionsAddressService={optionsAddressSupervisor}
              setOptionsAddressService={setOptionsAddressSupervisor}
              departaments={departamentsSupervisor}
              setDepartaments={setDepartamentsSupervisor}
              setGeoCodeService={setGeoCodeSupervisor}
              valueDepartamentService={valueDepartamentSupervisor}
              setValueDepartamentService={setValueDepartamentSupervisor}
              valueCityService={valueCitySupervisor}
              setValueCityService={setValueCitySupervisor}
              citiesService={citiesSupervisor}
              setCitiesService={setCitiesSupervisor}
            />
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Teléfono / Celular"
                name="cellphoneNumberSupervisor"
                control={control}
                error={errors}
                rule={Rules.telCelWithoutRequired}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{
                  maxLength: 10,
                  Rules: Rules.telCel,
                }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Correo Electrónico"
                name="emailSupervisor"
                control={control}
                rule={{
                  ...Rules.email,
                  validate: validateEmail, // personalizated validation
                }}
                error={errors}
                required
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{
                  maxLength: 100,
                }}
                classes={classes.typeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Cargo del supervisor"
                name="supervisorPosition"
                control={control}
                error={errors}
                rule={Rules.textoNumerosTildes}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{
                  maxLength: 100,
                }}
                classes={classes.typeInput}
              />
            </Grid>
          </Grid>
        );
      case 3:
        return selectComponent();
      case 4:
        return (
          <QuickSummary
            dataForm={{
              ...formData,
              documentTypes: getDocumentTypesContracts(
                documentTypes,
                personTypeService,
                serviceNameSelected,
                listDocumentTypeContracts,
              ),
              serviceList: servicesToAdd,
              productType,
              listValidityTypeContracts,
            }}
          />
        );
      default:
        return 'Paso no encontrado';
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paper}>
          <Typography color="primary" variant="h4" className={classes.title}>
            Convenios
          </Typography>
          <Container component="main" maxWidth="lg">
            <Paper elevation={3} className={classes.paperGroup}>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(handleNext)}
              >
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={0} />
                  <Grid item xs={12} style={{ paddingLeft: '33px' }}>
                    <Typography align="left" className={classes.titleForm} variant="h5">
                      Nuevo Convenio
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={4} style={{ paddingLeft: '60px', paddingRight: '60px' }}>
                  <Grid container justifyContent="center" style={{ marginBottom: '20px', paddingTop: '10px' }}>
                    <Grid item>
                      <Stepper activeStep={activeStep} alternativeLabel style={{ display: 'flex', justifyContent: 'center' }}>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                  {renderStepContent(activeStep)}
                  <Grid container spacing={2} style={{ alignItems: 'center' }}>
                    {activeStep > 0 ? (
                      <>
                        <Grid item xs={12} md={6} className={classes.subGridButtons}>
                          <Box className={classes.submit} display="flex" justifyContent="flex-start">
                            <Button
                              variant="contained"
                              color="inherit"
                              size="medium"
                              className={classes.btnSubmit}
                              onClick={handleBack}
                            >
                              Volver
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.subGridButtons}>
                          <Box className={classes.submit} display="flex" justifyContent="flex-end">
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="medium"
                              className={activeStep === 4 ? classes.btnBlueSubmit : classes.btnSubmit}
                              disabled={(!!((activeStep === steps.length - 2 && servicesToAdd.length === 0)) || invalidContract)}
                            >
                              {activeStep === steps.length - 1 ? 'Finalizar y guardar' : 'Siguiente'}
                            </Button>
                          </Box>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} className={classes.subGridButtons}>
                        <Box className={classes.submit} display="flex" justifyContent="flex-end">
                          <Button
                            type="submit"
                            variant="contained"
                            color="white"
                            size="medium"
                            className={classes.btnSubmit}
                            disabled={(!!((activeStep === steps.length - 1 && servicesToAdd.length === 0)) || invalidContract)}
                          >
                            {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                          </Button>
                        </Box>
                      </Grid>
                    )}

                    <Grid item xs={12} />
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Container>
        </Paper>
      </Container>
      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal={messagesAlertView?.tituloModal}
        textButtonAcept={messagesAlertView?.textButtonAcept}
        mensajeModal={messagesAlertView?.mensajeModal}
        handleAccept={() => confirmSaveContract()}
        onCancel={messagesAlertView?.onCancel}
        onClose={messagesAlertView?.onClose}
        messageAlert={messagesAlertView?.messageAlert}
        showClose={messagesAlertView?.showClose}
        isOnlyMessage={messagesAlertView?.isOnlyMessage}
      />
    </>
  );
};

const mapStateToProps = (props) => ({
  appName: props.general.appName,
  tenant: props.general.tenant,
  statesAndCities: props.general.statesAndCities,
  citiesServGoogleAssistant: props.google.citiesServGoogleAssistant,
  documentTypes: props.general.documentTypes,
  gestorType: props.license.gestorType,
  productType: props.home.productType,
  loading: props.license.loading,
  listPaymentTypeContracts: props.license.listPaymentTypeContracts,
  listDocumentTypeContracts: props.license.listDocumentTypeContracts,
  listValidityTypeContracts: props.license.listValidityTypeContracts,
  listDeviceTypeContracts: props.license.listDeviceTypeContracts,
  listCredentialDeliveryContracts: props.license.listCredentialDeliveryContracts,
  parameterMaxReassignmentQuota: props.license.parameterMaxReassignmentQuota,
  startDateParams: props.license.startDateParams,
  transformedListMaxValidityAmountContracts: props.license.transformedListMaxValidityAmountContracts,
  emailsInBalckList: props.emails.emailsBlackList,
  contractData: props.license.getContractData,
  decodedToken: props.auth.decodedToken,
  servicesCombo: props.service.servicesCombo,
  showMessageAlert: props.general.showMessageAlert,
});

const mapDispatchToProps = {
  getPurchaseOrderParameters: getPurchaseOrderParametersAction,
  saveContract: saveContractAction,
  getManagers: getManagersAction,
  getPackagesContracts: getPackagesContractsAction,
  getAppParametersContracts: getAppParametersContractsAction,
  getBlackListEmails: getBlackListEmailsAction,
  getContractData: getContractDataAction,
  getServices: getServicesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Agreements);
